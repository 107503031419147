// home page - index.js

import gruppe from "assets/bilder/_AD_9060_rgb_comp_final.jpg";
// import bg1 from "assets/bilder/home/AD_9060_rgb_composing_final.jpeg"
// import bg2 from "assets/bilder/home/BAD_0420_rgb.jpeg"
// import bg3 from "assets/bilder/home/BAD_0216_rgb.jpeg"
import bg3 from "assets/bilder/home/home_neu/BAP_2834_rgb_fine.jpg"
import bg2 from "assets/bilder/home/home_neu/BAP_3026-Verbessert-NR_rgb_fine.jpg"
import bg1 from "assets/bilder/home/home_neu/BAP_4279-Verbessert-NR_rgb_fine.jpg"
import bg4 from "assets/bilder/home/home_neu/BAP_4328-Verbessert-NR_rgb_fine.jpg"
import bg5 from "assets/bilder/home/home_neu/BAP_4349-Verbessert-NR_rgb_fine.jpg"
import bg6 from "assets/bilder/home/home_neu/BAP_4392-Verbessert-NR_rgb_fine.jpg"
import bg7 from "assets/bilder/home/home_neu/BAP_4444-Verbessert-NR_rgb_fine.jpg"
import bg8 from "assets/bilder/home/home_neu/BAP_5022-Verbessert-NR_rgb_fein.jpg"
import bg9 from "assets/bilder/home/home_neu/BAP_5165-Verbessert-NR_rgb_fine.jpg"
import bg10 from "assets/bilder/home/home_neu/BAP_6288_rgb_fine.jpg"
import bg11 from "assets/bilder/home/home_neu/BAP_6332_rgb_fine.jpg"

export const homeContent = {
    h2: "Qualität unter Strom",
    subtitle: "Planung, Installation und Inbetriebnahme",
    bgs: [
        bg1,bg2,bg3, bg4, bg5,bg6,bg7, bg8, bg9,bg10,bg11
      ],
    mainContent: `  <p>
    Seit der Gründung 1996 durch F. Huwiler, steht die Firma Elektro Huwiler 
    für zuverlässige, saubere Arbeit in Zusammenhang mit Telefon, 
    Energie und Strom. Diese Tradition wird in 
    neuen Händen seit einigen Jahren von Marcel Imhof und Stefan Alberici im selben Credo weitergeführt.
        <br></br>

        Die qualifizierten Mitarbeiter und unsere 
        spezifischen Erfahrungswerte in diversen Bereichen sind der Garant dafür, 
        Ihnen eine korrekte und saubere Ausführung der beauftragten Arbeiten zu gewährleisten.
        <br></br>

        Als flexibles, flinkes und kleines Team, haben wir uns auf Kleinaufträge spezialisiert. Von der Telefonaufschaltung,
         der Programmierung von Telefonanlagen für KMU's, Starkstrominstallationen,
          Realisierung von Photovoltaikanlagen und Installationen für die E-Mobilität 
          sind wir Ihr idealer Partner. 
        Auch kleinere Um- und Erweiterungsbauten führen wir gekonnt und sachkundig aus.
        <br></br>

        Bei Fragen rund um Energie – bei uns erhalten Sie Antworten.

        </p>
    `,
};