import React, { useState } from "react";
import { Layout, Seo } from "components/common";
import { Intro } from "components/Intro";
import { MainContent } from "components/MainContent";
import GoogleMap from "components/GoogleMap";

import { homeContent } from "pagesData/home";
import { IntroRando } from "components/IntroRandom";

const Home = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(undefined);

  return (
    <Layout>
      <Seo />
      <IntroRando {...homeContent} />
      <MainContent content={homeContent.mainContent} />

      {
        
        cookiesAccepted === false ? null 
        :
        cookiesAccepted ?
        (
          <GoogleMap />
          )
      :
      
      (
        <div
          style={{
            bottom: 40,
            width: "100%",
            // height: '300px',
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            position: "fixed",
          }}
        >
          <div
            style={{
              background: "#E5CF03",
              width:'380px',
              fontSize: "16px",
              border:'1px solid black',
              borderRadius: "10px",
              paddingBlock: "8px",
              paddingInline: "14px",
              color: "black",
              display:'flex',
              alignItems:'center'
              // height: '50px'
            }}
          >
            <span style={{marginRight:'15px', display:'block'}}>Wir benutzen Cookies. <a style={{color:'black'}} href="/impressum">mehr dazu</a> </span>
            <button
              style={{
                padding: "4px",
                marginInline: "6px",
                borderRadius: "5px",
                fontSize: "16px",
              }}
              onClick={() => setCookiesAccepted(true)}
            >
              Annehmen
            </button>
            <button
              style={{
                padding: "4px",
                marginInline: "2px",
                borderRadius: "5px",
                fontSize: "16px",
              }}
              onClick={() => setCookiesAccepted(false)}
            >
              Ablehnen
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default Home;
