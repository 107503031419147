import React from 'react'

let mapkey = 'AIzaSyDUJpxm61eRexXNwkDiEzas-MQ2z-CtTlc'

const GoogleMap = () => {
    return (
        <iframe width="100%" height="400" style={{ marginBottom: '-10px' }}
          src="https://maps.google.com/maps?width=100%&amp;height=400&amp;hl=de&amp;q=Stallikonerstrasse%2073+(AA)&amp;ie=UTF8&amp;t=&amp;z=16&amp;iwloc=B&amp;output=embed" 
          frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
        </iframe>
    )
}

export default GoogleMap;
